<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Kategori
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <multiselect 
                  id="category-name"
                  v-model="activityCategoryChoose"
                  v-validate="'required'"
                  label="name"
                  name="kategori"
                  track-by="name"
                  placeholder="Pilih..."
                  open-direction="bottom"
                  :options="dataActivityCategorys"
                  :searchable="true"
                  :loading="isLoading"
                  :close-on-select="true"
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectActivityCategory"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('kategori')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('kategori') }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Sub-Kategori
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <multiselect 
                  id="sub_activity_category"
                  v-model="subActivityCategoryChoose"
                  v-validate="'required'"
                  label="name"
                  name="sub_kategori_kegiatan"
                  track-by="name"
                  placeholder="Pilih..."
                  open-direction="bottom"
                  :options="dataSubActivityCategorys"
                  :searchable="true"
                  :loading="isLoading"
                  :close-on-select="true"
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectSubActivityCategory"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('sub_kategori_kegiatan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('sub_kategori_kegiatan') }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Nama Kegiatan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.name"
                v-validate="'required'"
                class="uk-input"
                type="text"
                name="nama"
                placeholder="Masukan nama kegiatan..."
                :class="{'uk-form-danger': errors.has('nama')}"
              >
              <div
                v-show="errors.has('nama')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('nama') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Keterangan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <textarea
                id=""
                v-model="formData.description"
                name="keterangan"
                :class="{'uk-textarea': true, 'uk-form-danger': errors.has('keterangan')}"
                cols="40"
                rows="5"
              />
              <div
                v-show="errors.has('keterangan')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('keterangan') }}
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Tipe Input
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <multiselect 
                  id="category-type"
                  v-model="inputTypeChoose"
                  v-validate="'required'"
                  label="name"
                  name="tipe_input"
                  track-by="name"
                  placeholder="Pilih..."
                  open-direction="bottom"
                  :options="dataInputTypes"
                  :searchable="true"
                  :loading="isLoading"
                  :close-on-select="true"
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectInputType"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('tipe_input')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('tipe_input') }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
              Waktu Pengerjaan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <timepicker
                  ref="createdStart"
                  v-model="formData.time_start"
                  v-validate="{ required: true }"
                  name="time_start"
                  input-class="uk-input time-class"
                  placeholder="hh:mm"
                  format="HH:mm"
                />  
                <span class="uk-text-center">
                  to
                </span>
                <timepicker
                  ref="createdEnd"
                  v-model="formData.time_end"
                  v-validate="{ required:true }"
                  name="time_end"
                  input-class="uk-input time-class"
                  placeholder="hh:mm"
                  format="HH:mm"
                />  
              </div>
            </div>
            <div
              v-show="errors.has('time_start')||errors.has('time_end') || errorTime"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first('time_start') || errors.first('time_end') || errorTime }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small uk-margin-top"
        uk-grid
      >
        <div class="uk-width-1-1 uk-text-right">
          <button
            class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/close.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Batal
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            type="button"
            @click="showSaveConfirmModal"
          >
            <img
              v-lazy="`${images}/icon/save.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import Timepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { PREFIX_IMAGE } from '@/utils/constant'
import { STATUS } from '@/utils/constant'

export default {
  components: {
    Timepicker
  },
  props: {
    dataDetail: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      errorTime:'',
      checkTimeEnd: true,
      checkTimeStart: true,
      isShowFarm: false,
      isLoading: false,
      images: PREFIX_IMAGE,
      status: STATUS,
      datepick: new Date(),
      statusSwitch: true,
      formData: {},
      activityCategoryChoose: {},
      subActivityCategoryChoose: {},
      inputTypeChoose: {},
      dataActivityCategorys: [],
      dataSubActivityCategorys: [],
      dataInputTypes: []
    }
  },
  computed: {
    ...mapGetters({
      activityCategorys: 'activityCategory/activityCategorys',
      subActivityCategorys: 'subActivityCategory/subActivityCategorys',
      inputTypes: 'inputType/inputTypes'
    })
  },
  watch: {
    'formData.time_start'() {
      if(this.formData.time_start.includes('.')){
        this.formData.time_start = ''
      }
      this.checkTimeStart = this.formData.time_start.includes('HH') ||
    this.formData.time_start.includes('mm') || this.formData.time_start == '' ? true : false
      this.errorTime = this.checkTimeStart ? 'Pastikan jam dan menit sudah terisi' : ''
    }, 
    'formData.time_end'() {
      if(this.formData.time_end.includes('.')){
        this.formData.time_end = ''
      }
      this.checkTimeEnd = this.formData.time_end.includes('HH') ||
    this.formData.time_end.includes('mm') || this.formData.time_end == '' ? true : false
      this.errorTime = this.checkTimeEnd ? 'Pastikan jam dan menit sudah terisi' : ''
    },
    async dataDetail() {
      if (this.dataDetail) {
        this.formData = {
          id: this.dataDetail.id,
          name: this.dataDetail.name,
          activity_category_id: this.dataDetail.activity_category_id,
          sub_activity_category_id: this.dataDetail.sub_activity_category_id,
          input_type_id: this.dataDetail.input_type_id,
          description: this.dataDetail.description,
          time_start: this.dataDetail.time_start,
          time_end: this.dataDetail.time_end
        }
        this.activityCategoryChoose = { id: this.dataDetail.activity_category_id, name: this.dataDetail.activity_category }
        this.subActivityCategoryChoose = { id: this.dataDetail.sub_activity_category_id, name: this.dataDetail.sub_activity_category }
        this.inputTypeChoose = { id: this.dataDetail.input_type_id, name: this.dataDetail.input_type }

        await this.getActivityCategory({is_all: true})
        await this.getSubActivityCategory({is_all: true})
        await this.getInputType({is_all: true})

        this.dataActivityCategorys = this.activityCategorys ? this.activityCategorys : []
        this.dataSubActivityCategorys = this.subActivityCategorys ? this.subActivityCategorys : []
        this.dataInputTypes = this.inputTypes ? this.inputTypes : []
      }
    }
  },
  methods: {
    onChangeRole(event) {
      const valRole = event.target.options[event.target.options.selectedIndex].text
      if (valRole === 'Super Admin')
        this.isShowFarm = false
      else
        this.isShowFarm = true
    },
    ...mapActions({
      getActivityCategory: 'activityCategory/getActivityCategory',
      getSubActivityCategory: 'subActivityCategory/getSubActivityCategory',
      getInputType: 'inputType/getInputType'
    }),
    ...mapMutations({
      setModalEdit: 'detailActivityCategory/SET_MODAL_EDIT'
    }),
    onSelectActivityCategory(val) {
      this.formData.activity_category_id = val.id
    },
    onSelectSubActivityCategory(val) {
      this.formData.sub_activity_category_id = val.id
    },
    onSelectInputType(val) {
      this.formData.input_type_id = val.id
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if(!this.checkTimeStart&&!this.checkTimeEnd){
            if (this.$validator.errors.any()) return
            this.setModalEdit(this.formData)
            window.UIkit.modal('#modal-save-confirm').show()
          }
        }
      })
    }
  }
}
</script>
